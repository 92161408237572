<template>
  <transition name="slide-fade" mode="out-in">
    <div class="forgot">
      <div class="logo"></div>
      <h5 class="text-center">Forgot password</h5>
      <form v-if="!response" @submit.prevent="onLogin">
        <input
          @change="reset"
          type="email"
          required
          placeholder="Email"
          v-model="user.emailAddress"
        />

        <button :disabled="loading" class="btn auth-btn" @click="onforgot">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Reset</span>
        </button>
      </form>
      <h6>{{ response }}</h6>
      <router-link v-show="!response" style="float: right" to="/auth/login"
        >Sign in?</router-link
      >
      <div class="error-container">
        <transition name="slide-fade" mode="out-in">
          <p v-show="error" class="error">{{ error }}</p>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapMutations } from "vuex";
import ikebana from "../../services/ikebana";

export default {
  data() {
    return {
      loading: false,
      user: {
        emailAddress: null,
        password: null,
      },
      response: null,
      error: null,
    };
  },
  methods: {
    reset() {
      this.error = null;
    },
    ...mapMutations(["SET_USER"]),
    onforgot() {
      this.loading = true;
      ikebana.forgot(this.user.emailAddress).then(
        (response) => {
          this.response = response.data;
          this.loading = false;
        },
        (error) => {
          this.error = error.response.data.Message;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style lang="css" scoped>
.error-container {
  height: 100px;
  min-height: 100px;
}
.error {
  color: red;
  text-align: center;
  margin-top: 50px;
}
@media (max-width: 768px) {
  .forgot {
    width: 100% !important;
    min-height: 70vh !important;
  }
}
.error-container {
  height: 100px;
  min-height: 100px;
}
.error {
  color: red;
  text-align: center;
  margin-top: 50px;
}
@media (max-width: 768px) {
  .login {
    width: 90vw !important;
    min-height: 80vh !important;
    padding: 20px !important;
  }

  .auth-btn {
    bottom: 0;
    left: 0;
    margin-bottom: 10px;
    margin-left: 10px;
    height: 50px;
    position: absolute;
    width: 85vw !important;
  }
  form {
    margin-top: 70px;
  }
}
.auth-btn {
  width: 100%;
  color: white;
  border-radius: 12px;
  background: #b52025;
}
a {
  text-decoration: none;
  text-align: center;
  color: #707070;
}
.forgot input {
  margin-bottom: 10px;
}

input {
  width: 100% !important;
  height: 50px;
}

.logo {
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background: url("../../assets/logo.png") center center no-repeat;
}
.auth-btn {
  border-radius: 12px;
  background: #ad1f23;
}
a {
  text-decoration: none;
  text-align: center;
  color: #707070;
}
.forgot {
  background: white;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  align-content: center;
  min-width: 40vw !important;
  justify-content: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px 50px 20px 50px;
}

.forgot input {
  margin-bottom: 10px;
}

input {
  width: 100%;
  height: 50px;
}

.logo {
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background: url("../../assets/logo.png") center center no-repeat;
}
</style>
